import React from 'react';
import { graphql } from 'gatsby';

import getServiceColor from 'helpers/getServiceColor';

import Layout from 'components/Layout/Layout';
import Text from 'components/UI/Text/Text';
import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';
import Section from 'components/UI/Section/Section';

import ServicesList from 'components/Services/List';

const SERVICE_TYPES = [
  'Open innovation',
  'Funding',
  'Sales'
];

const Services = ({ data }) => {
  const items = data.allContentfulService.edges;
  const servicesByType = SERVICE_TYPES.reduce((acc, type) => ({
    ...acc,
    [type]: items.filter(({ node }) => node.typeOfService === type)
  }), {});

  return (
    <Layout page="services">
      <Section>
        <Text variant="h2" line stack>
          Services
        </Text>
        <ContentfulRichText richTextAST={data.allContentfulPage.edges[0].node.subtitle.json} variant="desc" stack />

        {Object.keys(servicesByType).map(type => (
          <ServicesList
            key={type}
            title={type}
            data={servicesByType[type]}
            serviceColor={getServiceColor(type)}
          />
        ))}
      </Section>
    </Layout>
  );
};

export default Services;
export const query = graphql`
  query {
    allContentfulService(
      sort: { fields: typeOfService }
      limit: 20
    ) {
      pageInfo { hasNextPage }
      totalCount
      edges {
        node {
          id
          title
          subtitle
          description { json }
          typeOfService
        }
      }
    }
    allContentfulPage(
      filter: {
        slug: {
          eq: "services"
        }
      }
    ) {
      edges {
        node {
          slug
          title
          subtitle { json }
          blockInfo { json }
          blockFeature { json }
          clients
          partners
          caseStudies
        }
      }
    }

  }
`;

